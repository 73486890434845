import { t } from "i18next";
import { upload, uploadAndGetRecno } from "../../services/api/dataServiceOffice";

export const getFiledata = (sucCb: (fileArray: Iterable<number>) => void, errCb: (err: string) => void) => {
    const getAllSlices = (file: Office.File, sucCb: (arg0: never[]) => void) => {
        const sliceCount = file.sliceCount;
        let sliceIndex = 0;
        let docdata: never[] = [];
        const getSlice = function () {
            file.getSliceAsync(sliceIndex,
                function (asyncResult: { status: Office.AsyncResultStatus; value: { data: ConcatArray<never>; }; error: { message: string; }; }) {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        docdata = docdata.concat(asyncResult.value.data);
                        sliceIndex++;
                        if (sliceIndex === sliceCount) {
                            file.closeAsync();
                            sucCb(docdata);
                        }
                        else {
                            getSlice();
                        }
                    }
                    else {
                        file.closeAsync();
                    }
                });
        };
        getSlice();
    };
    try {
        Office.context.document.getFileAsync(Office.FileType.Compressed, function (asyncResult) { //{ sliceSize: 10240 },
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                errCb(asyncResult.error.message);
            }
            else {
                getAllSlices(asyncResult.value, sucCb);
            }

        });
    } catch (e) {
        errCb("Exception when reading file");
    }
};
export const UploadService = {
    uploadFileAndGetToken: (filename: string,
        successCb: (resData: string | null) => void, errorCb: (err: string) => void) => {
        getFiledata(async (fileArray: Iterable<number>) => {

            const fileToken = await upload(new Uint8Array(fileArray), filename);
            if (fileToken) {
                uploadAndGetRecno(fileToken, filename, successCb);
            } else {
                errorCb(t("Upload failed"));
            }
        }, errorCb);
    }
};

export const getfileNameFromUrl = (url: string): string | undefined => {
    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
        return url.split("/").pop();
    } else {
        if (url.includes('https')) {
            return url.split("/").pop();
        }
        return url.substring(url.lastIndexOf('\\') + 1);
    }
};

export const getPrerequisiteData = () => {

    if (Office.context.document?.url) {
        const docUrl = Office.context.document.url;
        const fileName = getfileNameFromUrl(docUrl);
        const fileId = extractFileId(docUrl);

        const prereqData = {
            fileId: fileId, // need to find it when doing checkout flow
            serverName: '',
            fileName: fileName
        };
        return prereqData;
    } else {
        return null;
    }

};
export const extractFileId = (url: string): string | null => {
    const regex = /(\d+)[_\s](\d+)_\d+\.[a-zA-Z0-9]+$/;
    const match = url.match(regex);
    return match ? match[1] : null;
};