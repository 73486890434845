import React, { useCallback, useId } from 'react';
import { Button, makeStyles, mergeClasses, Text, Toaster, ToastIntent, useToastController } from '@fluentui/react-components';
import { IDocumentDetails } from '../../../@types/models';
import { Document } from '@si/fib-icons';
import { openLink } from '../../../services/api/dialogService';
import { t } from "i18next";
import { isOutlookApp } from '../../../utils/Helper';
import SpinnerComponent from '../controls/spinner';
import { getPrerequisiteData } from '../../../Office/archiveFile/fileActionUtils';
import { checkIn, getAccessTokenFromGraph, undoCheckout } from '../../../services/api/dataServiceOffice';
import { getToastMessageComponent } from '../ToastMessage';

const useStyles = makeStyles({
    ctnButton:
    {
        borderRadius: "4px",
        background: "#0F6CBD",
        color: 'white'
    },
    btnMarginTop: {
        marginTop: '30px'
    },
    detailBody: {
        width: '100%',
        justifyContent: "space-between",
        display: 'flex',
        flexDirection: "column"
    },
    containerDetails: {
        minHeight: 'inherit'
    },
    containerHeaderDiv: {
        borderBottom: "1px solid #00000026",
    },
    containerHeaderItemHeading: {
        gap: "8px",
        fontSize: "16px",
        fontWeight: "600",
        padding: "8px",
        display: "flex",

    },
    containerHeaderItemLogo: {
        borderRadius: "100px",
        gap: "10px",
        backgroundColor: "#B5D9D94D",
        justifyContent: 'center',
        width: "42px",
        height: "42px",
        display: "flex"
    },
    containerHeaderLogo: {
        paddingTop: "10px",
        height: "20px",
    },
    containerHeaderItemNumber: {
        fontWeight: "600",
        fontSize: "14px",
        color: "rgba(66, 66, 66, 0.60)",
        fontStyle: "normal",
        lineHeight: "20px"
    },
    containerBodyDiv: {
        gap: "12px",
        display: 'flex',
        flexDirection: 'column',
        marginTop: "12px"
    },
    containerBodyFlex: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerBodyTextHeading:
    {
        padding: "0px, 12px, 0px, 12px",
        fontSize: "16px",
        fontWeight: "600",
        gap: "10px"
    },
    containerBodyTextDetails:
    {
        padding: "0px, 12px, 0px, 12px",
        fontSize: "14px",
        gap: "10px",
        lineHeight: "20px",
        fontWeight: "400s",
        color: "#616161"
    },
    containerButton: {
        display: "flex",
        justifyContent: 'flex-end'
    },
    marginRight5: {
        marginRight: '5px'
    }

});

const ArchiveDetail: React.FC<IDocumentDetails> = (props: IDocumentDetails) => {
    const styles = useStyles();
    const [showSpinner, setShowSpinner] = React.useState(false);
    const toasterId = useId();
    const { dispatchToast, dismissToast } = useToastController(toasterId);
    const openText = t('Open document in 360');
    const checkInText = t('Check-in file');
    const undotext = t('Undo check-out');
    const loadingText = t("Archiving files to 360...");
    const undoCheckOutFailedMsg = t('Undo Check-out failed');
    const checkInFailedMsg = t('Checkin failed');
    const responsible = (props.ResponsibleName ? props.ResponsibleName + " - " : "") + props.OrgUnitName;
    const accessCodeAndParagraph = props.AccessCodeDesc + (props.Paragraph ? " / " + props.Paragraph : "");

    const showToast = (message: string, toastType: ToastIntent) => {
        dispatchToast(
            getToastMessageComponent(message, checkinBLcall),
            { timeout: -1, intent: toastType }
        );
    };

    const checkinBLcall = useCallback(async () => {
        dismissToast(toasterId);
        setShowSpinner(true);
        const prereqData = getPrerequisiteData();
        if (prereqData?.fileName) {

            const isWeb = (Office.context.platform === Office.PlatformType.OfficeOnline) ? "True" : "False";
            Office.context.ui.displayDialogAsync(
                window.location.origin + "/dialogCompleted.html?checkin=true&isWeb=" + isWeb,
                { promptBeforeOpen: true, displayInIframe: true, height: 50, width: 50 });

            const prereqData = getPrerequisiteData();
            if (prereqData?.fileId) {
                const res = await checkIn(prereqData.fileId);
                if (res) {
                    setShowSpinner(false);
                    //set fileststus to checked in
                    const isWeb = (Office.context.platform === Office.PlatformType.OfficeOnline) ? "True" : "False";
                    Office.context.ui.displayDialogAsync(
                        window.location.origin + "/dialogCompleted.html?checkin=true&isWeb=" + isWeb,
                        { promptBeforeOpen: true, displayInIframe: true, height: 50, width: 50 });
                } else {
                    setShowSpinner(false);
                    showToast(checkInFailedMsg, "error");
                }
            }
        }

    }, [dismissToast, showToast, toasterId, checkInFailedMsg]);

    const checkinFileClick = useCallback(() => {

        if (Word && Word.run) {
            void Word.run(async (context) => {
                await context.sync();
                void checkinBLcall();
            });
        } else {
            void checkinBLcall();
        }
    }, [checkinBLcall]);

    const undocheckOut = useCallback(async () => {
        setShowSpinner(true);

        const token = await getAccessTokenFromGraph();
        console.log(token);
        setShowSpinner(false);

        const prereqData = getPrerequisiteData();
        if (prereqData?.fileId) {
            const res = await undoCheckout(prereqData.fileId);
            if (res) {
                setShowSpinner(false);
                //set fileststus to checked in
                const isWeb = (Office.context.platform === Office.PlatformType.OfficeOnline) ? "True" : "False";
                Office.context.ui.displayDialogAsync(
                    window.location.origin + "/dialogCompleted.html?checkin=false&isWeb=" + isWeb,
                    { promptBeforeOpen: true, displayInIframe: true, height: 50, width: 50 });
            } else {
                const token = await getAccessTokenFromGraph();
                console.log(token);
                setShowSpinner(false);
                showToast(undoCheckOutFailedMsg, "error");
            }
        }

    }, [showToast, undoCheckOutFailedMsg]);

    const openDocBtnStyle = mergeClasses(styles.ctnButton, !isOutlookApp() ? styles.btnMarginTop : '');
    return (
        <div className={styles.detailBody}>
            <div className={styles.containerDetails}>
                <div className={styles.containerHeaderDiv}>
                    <div className={styles.containerHeaderItemHeading}>
                        <div className={styles.containerHeaderItemLogo}>
                            <Document className={styles.containerHeaderLogo} size={16}></Document></div>
                        <div>
                            <div>{props.DocumentTitle}</div>
                            <div className={styles.containerHeaderItemNumber}>
                                {props.ArchiveCode + " : " + props.DocumentNumber}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.containerBodyDiv}>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Case')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{props.CaseNumber + " " + props.CaseDescription}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Document Archive')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{props.ArchiveCode}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Document category')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{props.CategoryCode}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Responsible person')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{responsible}</Text>
                    </div>
                    <div className={styles.containerBodyFlex}>
                        <Text className={styles.containerBodyTextHeading}>{t('Access code')}</Text>
                        <Text className={styles.containerBodyTextDetails}>{accessCodeAndParagraph}</Text>
                    </div>
                </div>
            </div>
            <div className={styles.containerButton}>
                <Button className={openDocBtnStyle} aria-label={openText} onClick={() => openLink(props.DocumentDetailsLink)}>{openText}</Button>
            </div>
            {!isOutlookApp() ?
                <div className={mergeClasses(styles.containerButton, styles.btnMarginTop)}>
                    <Button className={mergeClasses(styles.ctnButton, styles.marginRight5)}
                        aria-label={checkInText} onClick={checkinFileClick} >{checkInText}</Button>
                    <Button className={styles.ctnButton} aria-label={undotext} onClick={undocheckOut} >{undotext} </Button>
                </div>
                : null
            }
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' />
        </div>
    );
};
export default ArchiveDetail;
