import React, { useCallback } from 'react';
import { FileMono, MailWithQuestion } from '@si/fib-icons';
import { Button, makeStyles } from '@fluentui/react-components';
import { t } from "i18next";
import { useNavigate } from 'react-router-dom';
import { isOutlookApp } from '../../../utils/Helper';
import { getAccessTokenFromGraph, getRefreshToken, getTheAccessToken } from '../../../services/api/dataServiceOffice';

const useStyles = makeStyles({
    ctnButton:
    {
        borderRadius: "4px",
        background: "#0F6CBD",
        color: 'white'
    },
    archiveBody:
    {
        minHeight: "inherit",
        padding: "24px",
        justifyContent: "flex-start",
        display: 'flex',
        flexDirection: 'column'
    },
    archiveHeading: {
        leadingTrim: "both",
        textEdge: "cap",
        fontStyle: "normal",
        fontFamily: "Segoe UI",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "normal",
        textAlign: 'center',
        display: "flex",
        alignItems: "center",
        gap: "8px",
        height: "24px"
    }

});

const ArchiveHome: React.FC = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const undocheckOut = useCallback(async () => {

        const token = await getAccessTokenFromGraph();
        console.log(token);
        const accessToken = await getTheAccessToken();
        console.log(accessToken);
        console.log(document.cookie);
        const refreshToken = await getRefreshToken();
        console.log(refreshToken);

    }, []);

    return (
        <div className={styles.archiveBody}>
            <div className={styles.archiveHeading}>
                {isOutlookApp() && <MailWithQuestion size={24} />}
                {!isOutlookApp() && <FileMono size={24} />}

                <span>&nbsp;{t('Not archived in 360°')}</span>
            </div>
            {isOutlookApp() &&
                <>
                    <p>{t('The selected email is currently not archived in 360°, click the button below to continue.')}</p>
                    <Button className={styles.ctnButton} onClick={() => navigate('/archiveMail')}>{t('Save to 360')}</Button>
                </>
            }
            {!isOutlookApp() &&
                <>
                    <p>{t('The selected file is currently not archived in 360°, click the button below to continue.')}</p>
                    <Button className={styles.ctnButton} onClick={() => navigate('/archiveFile')}>{t('Save to 360')}</Button>
                </>
            }
            <Button className={styles.ctnButton} aria-label={"Test Token"} onClick={undocheckOut} >{"Test Token"} </Button>
        </div>
    );
};

export default ArchiveHome;
