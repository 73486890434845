

const OFFICE_ADDIN_BASE_URL = "/SI.Biz.Core.OfficeAddin/SI.Biz.Core.OfficeAddin/";
const OFFICE_ADDIN_OUTLOOK_SERVICE = "OutlookService/";
const OFFICE_ADDIN_OFFICE_SERVICE = "OfficeService/";
const SUPPORT = "/Biz/v2/support/";

enum API_NAMES {
    PING = "Ping",
    GET_ARCHIVE = "GetArchivedMailInfo",
    SAVE_MAIL = "SaveMailUsingNewOutlook",
    GET_PROD_NAME = "GetProductName",
    GETCONFIG = "GetConfigurations",
    UPLOAD = "uploadfile",
    SAVEFILE_FROM_OFFICE = "SaveFileFromOffice",
    GET_ARCHIVE_FILE = "GetArchivedFileInfo",
    GET_FILEDETAILS_FROM_DOCUMENT = "GetDetailFromDocument",
    CHECKIN = "Checkin",
    UNDO_CHECKOUT = "UndoCheckout",
    GET_TOKEN = "GetAccessToken",
    REFRESH_TOKEN = "RefreshAccessToken"
}

export class ApiConfig {

    public static readonly getConfig: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.GETCONFIG;
    public static readonly checkConnection: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.PING;
    public static readonly GetArchivedMailInfo: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.GET_ARCHIVE;
    public static readonly SaveMail: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OUTLOOK_SERVICE + API_NAMES.SAVE_MAIL;
    public static readonly getProductName: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.GET_PROD_NAME;
    public static readonly upload = SUPPORT + API_NAMES.UPLOAD;
    public static readonly SaveFileFromOffice = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.SAVEFILE_FROM_OFFICE;
    public static readonly GetDetailFromDocument: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.GET_FILEDETAILS_FROM_DOCUMENT;
    public static readonly GetArchivedFileInfo: string = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.GET_ARCHIVE_FILE;
    public static readonly checkingFile = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.CHECKIN;
    public static readonly undocheckOut = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.UNDO_CHECKOUT;
    public static readonly getAcessToken = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.GET_TOKEN;
    public static readonly refreshToken = OFFICE_ADDIN_BASE_URL + OFFICE_ADDIN_OFFICE_SERVICE + API_NAMES.REFRESH_TOKEN;
}

export const getFetchApiOptions = () => {
    return {
        mode: "cors",
        credentials: "include",
        redirect: "error"
    };

};

export const getEnvironment360BizURL = () => {
    return getEnvironment360URL() + "/Biz/v2/api/call";
};

export const getEnvironment360URL = () => {
    let public360Url = localStorage.getItem("public360Url");

    if (public360Url === "")
        public360Url = new URLSearchParams(window.location.search).get("url");

    return public360Url ?? "";
};
